import topic from '../topic';
import { postNoCatch } from '@api/request';

export const stisAPI = {
  getPpins: topic.ppinTopic + '/get_ppins',
  getSoins: topic.soinTopic + '/get_soins',
  getHoins: topic.hoinTopic + '/get_hoins',
  exportHoin: topic.hoinTopic + '/exportHoin',
  export_soins: topic.soinTopic + '/export_soins',
  getRpins: topic.rpinTopic + '/get_rpins',
  editRpin: params => postNoCatch(`${topic.rpinTopic}/editRpin`, params),
  getCusas: topic.cusaTopic + '/get_cusas',
  getRpinsFees: topic.rpinTopic + '/get_rpins_fees',
  getOuins: topic.ouinTopic + '/get_ouins',
  finishCollection: params => postNoCatch(`${topic.soinTopic}/finishCollection`, params),
  finihkCollection: params => postNoCatch(`${topic.hoinTopic}/finishCollection`, params),
  editOperationStatus: params => postNoCatch(`${topic.rpinTopic}/editOperationStatus`, params),
  getTranCustGroupList: topic.tranTopic + '/getTranCustGroupList',
  getTranNotCustGroupList: topic.tranTopic + '/getTranNotCustGroupList',
  getTranCustDetailList: topic.tranTopic + '/getTranCustDetailList',
  exportTranCustGroup: topic.tranTopic + '/exportTranCustGroup',
  exportTranCustDetailList: topic.tranTopic + '/exportTranCustDetailList'
};
